/* Terms Container Styles */
.terms-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    /* Ensures container height is limited */
    overflow-y: auto;
    /* Enables scroll when content exceeds max height */
}

h1 {
    text-align: center;
    color: #333;
}

h2 {
    margin-top: 20px;
    color: #222;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
}

h3 {
    margin-top: 15px;
    color: #444;
}

p,


ul {
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}