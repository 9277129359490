/* Dashboard.css */

.dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin-bottom: 100px;
}

.dashboard-sections {
    width: 200px;
    background-color: #333;
    color: white;
    padding: 20px;
    box-sizing: border-box;
}

.dashboard-sections ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dashboard-sections ul li {
    margin-bottom: 10px;
    cursor: pointer;
    display: block;
}

.dashboard-sections ul li:hover {
    text-decoration: underline;
}

.dashboard-content {
    flex: 1;
    padding: 20px;
    background-color: #f0f0f0;
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 100px;
}

.dashboard-content h3 {
    margin-top: 0;
}

.dashboard-content div {
    margin-bottom: 20px;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .dashboard-container {
        flex-direction: column;
        /* Stack sections vertically */
    }

    .dashboard-sections {
        width: 100%;
        /* Full width for sections on tablets */
    }

    .dashboard-content {
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .dashboard-content h3 {
        font-size: 1.5em;
        /* Adjust font size for headers on tablets */
    }

    .dashboard-content div {
        margin-bottom: 15px;
        /* Adjust margin for content sections on tablets */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .dashboard-container {
        flex-direction: column;
        width:100%
        /* Stack sections vertically */
    }

    .dashboard-sections {
        width: 100%;
        /* Full width for sections on mobile devices */
    }

    .dashboard-content {
        padding: 40px;
        margin-bottom: 100px;
        width:100%;
        /* Adjust padding for mobile devices */
    }

    .dashboard-content h3 {
        font-size: 1.2em;
        /* Adjust font size for headers on mobile devices */
    }

    .dashboard-content div {
        margin-bottom: 10px;
        /* Adjust margin for content sections on mobile devices */
    }
}