/* TestSummary.css */

.dashboard-content {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#testenv-select {
    margin-bottom: 20px;
}

.test-summary-table {
    width: 100%;
    border-collapse: collapse;
}

.test-summary-table th,
.test-summary-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.test-summary-table th {
    background-color: #f2f2f2;
    cursor: pointer;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .dashboard-content {
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .test-summary-table th,
    .test-summary-table td {
        padding: 8px;
        /* Adjust padding for table cells */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .dashboard-content {
        padding: 10px;
        /* Adjust padding for mobile devices */
        border-radius: 5px;
        /* Adjust border radius for mobile devices */
    }

    #testenv-select {
        margin-bottom: 15px;
        /* Adjust margin for select */
    }

    .test-summary-table {
        font-size: 14px;
        /* Adjust font size for table on mobile devices */
    }

    .test-summary-table th,
    .test-summary-table td {
        padding: 6px;
        /* Adjust padding for table cells */
    }
}