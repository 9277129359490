.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.login-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
}

.login-form h2 {
    margin-bottom: 20px;
    text-align: center;
}

.login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.password-input-container {
    margin-bottom: 10px;
}

.show-password-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.show-password-container label {
    margin-right: 5px;
}

.login-form button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.login-form button:hover {
    background-color: #0056b3;
}

.login-links {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.login-links a {
    color: #007bff;
    text-decoration: none;
}

.login-links a:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .login-form {
        width: 80%;
        padding: 15px;
    }

    .login-form input,
    .login-form button {
        padding: 8px;
        font-size: 14px;
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .login-form {
        width: 90%;
        padding: 10px;
    }

    .login-form h2 {
        font-size: 18px;
    }

    .login-form input,
    .login-form button {
        padding: 8px;
        font-size: 14px;
    }

    .login-links {
        flex-direction: column;
        align-items: center;
    }

    .login-links a {
        margin-top: 5px;
    }
}