/* General Page Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding-bottom: 100px;
    overflow: hidden;
    /* Prevents body scrolling, allowing container-level scroll */
}

/* Banner Styling with Animation */
.banner {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 40px 0;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;

    /* Animation properties */
    transform: translateX(100%);
    /* Start position (off-screen) */
    animation: slideInFromRight 1.2s ease-out forwards;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.banner span {
    position: relative;
    z-index: 1;
}

.banner::after {
    content: '*****';
    position: absolute;
    top: calc(50% + 30px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: #FFD700;
    z-index: 0;
}

/* About Container - Full Page Scrollable */
.about-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 200px);
    /* Ensures it takes most of the viewport */
    width: 100%;
    overflow-y: auto;
    /* Enables scrolling for both sections */
    align-items: flex-start;
    /* Ensures natural height growth */
}

/* Left Section (Adjusts with Scrolling) */
.about-left-section {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    width: 20%;
    min-height: 68%;
    /* Stretches along with the right section */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;   
}

/* Right Section (Grows Based on Content) */
.about-right-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;  
    flex-grow: 1;
    /* Ensures this section grows dynamically */
}

/* Individual Content Boxes */
.about-box {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    line-height: 1.5;
}
.video-container {
    width: 560px;
    /* Adjust width as needed */
    max-width: 100%;
    margin: 20px auto;
    text-align: center;
}

.video-container iframe {
    width: 100%;
    height: 315px;
    /* Standard YouTube embed height */
    border: none;
}
.footer-row {
    display: flex;
    justify-content: space-between;
    /* Spreads items across the row */
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.footer-left {
    text-align: left;
    flex: 1;
}

.footer-center {
    text-align: center;
    flex: 1;
}

.footer-right {
    text-align: right;
    flex: 1;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .about-container {
        flex-direction: column;
        overflow-y: auto;
        /* Maintain scrolling for smaller screens */
    }

    .about-left-section,
    .about-right-section {
        width: 100%;
    }

    .about-left-section {
        min-height: auto;
        /* Allow it to shrink naturally */
        margin-bottom: 20px;
    }

    .about-box {
        margin: 10px 0;
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .about-container {
        flex-direction: column;
    }

    .about-left-section,
    .about-right-section {
        width: 100%;
    }

    .about-left-section {
        min-height: auto;
        margin-bottom: 20px;
        padding: 10px;
        font-size: 20px;
    }

    .about-box {
        margin: 10px 0;
        padding: 15px;
        font-size: 16px;
    }
}