.table-testfailure {
  width: 100%;
  border-collapse: collapse;
}

.table-testfailure th,
.table-testfailure td {
  border: 0;
  /* Remove borders */
  padding: 8px;
  text-align: left;
}

.table-testfailure th {
  background-color: #f2f2f2;
}

.table-testfailure td {
  position: relative;
}

.table-testfailure td button {
  display: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.table-testfailure td:hover button {
  display: inline;
}

.table-testfailure textarea {
  width: 100%;
  resize: vertical;
}

.table-testfailure input[type="text"],
.table-testfailure input[type="date"],
.table-testfailure input[type="number"] {
  width: calc(100% - 30px);
}

.table-testfailure button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.table-testfailure button:hover {
  background-color: #45a049;
}
.field-value {
  margin-top: 5px;
}
