/* Container to hold sidebar, content, and right sidebar */
.edit-runschedule-container {
  display: flex;
  flex-direction: row;
  padding: 0; /* Remove padding to eliminate gaps */
  overflow-y: hidden; /* Disable vertical scrolling for larger screens */
}

/* Sidebar styling */
.left-sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  margin: 0; /* Remove margin to eliminate white space */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.left-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.left-sidebar ul li {
  margin-bottom: 10px;
}

.left-sidebar ul li a {
  text-decoration: none;
  color: #333;
}

.left-sidebar ul li a:hover {
  text-decoration: underline;
}

/* Content area styling */
.main-content {
  flex: 1;
  margin: 0 20px;
  padding: 20px; /* Add padding to main content */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Right sidebar styling */
.right-sidebar {
  width: 300px;
  background-color: #f4f4f4;
  padding: 20px;
  border-left: 1px solid #ddd;
  margin: 0; /* Remove margin to eliminate white space */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
  .edit-runschedule-container {
    flex-direction: column; /* Stack sidebar and content vertically */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .left-sidebar,
  .right-sidebar {
    width: 100%; /* Full width for sidebars on small screens */
    margin-left: 0;
  }

  .main-content {
    margin: 0; /* Adjust margin for main content */
    padding: 15px;
  }

  .right-sidebar {
    margin-top: 20px; /* Add margin to separate from other elements */
    margin-bottom: 50px; /* Ensure bottom margin on smaller screens */
    max-height: calc(100vh - 70px); /* Adjust height for full visibility */
  }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
  .edit-runschedule-container {
    flex-direction: column; /* Stack sidebar and content vertically */
    overflow-y: auto; /* Enable vertical scrolling for mobile */
  }

  .left-sidebar,
  .right-sidebar {
    width: 100%; /* Full width for sidebars on small screens */
    margin-left: 0;
  }

  .main-content {
    margin: 0; /* Adjust margin for main content */
    padding: 10px;
  }

  .right-sidebar {
    margin-top: 20px; /* Add margin to separate from other elements */
    margin-bottom: 50px; /* Ensure bottom margin on mobile devices */
    max-height: calc(100vh - 70px); /* Adjust height for full visibility */
  }

  .left-sidebar ul li,
  .right-sidebar ul li {
    margin-bottom: 8px;
  }
}
