/* index.css */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* Prevent body from scrolling */
  /* Ensure the browser scrollbar is not used */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

#root {
  height: 100vh;
  /* Full viewport height */
  overflow: hidden;
  /* Ensure root container doesn't scroll */
}

.app-container {
  height: 100%;
  overflow-y: auto; 
  /* Allow scrolling within the app container */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}