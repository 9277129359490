.assigned-projects-container {
    padding: 16px;
}

.assigned-projects-table {
    width: 100%;
    border-collapse: collapse;
}

.assigned-projects-table th,
.assigned-projects-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.assigned-projects-table th {
    cursor: pointer;
    background-color: #f2f2f2;
}

