.generate-auth-token {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.generate-auth-token h2 {
    margin-bottom: 20px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.input-container {
    margin-bottom: 20px;
}

.input-container label {
    margin-right: 10px;
}

.input-container input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.input-container button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.input-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.token-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.token-table th,
.token-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.token-table th {
    background-color: #f2f2f2;
}

.token-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}