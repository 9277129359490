.home-container {
    max-height: 100vh;
    /* 100% of the viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding: 20px;
    /* Optional: padding around the content */
    margin-bottom: 150px;
}
.pagination {
    margin-top: 16px;
    text-align: center;
    margin-bottom: 50px;
}

.pagination button {
    margin: 0 2px;
    padding: 8px 12px;
    cursor: pointer;
}
