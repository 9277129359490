/* Navbar styling */
nav {
    background-color: #333;
    overflow: hidden;
}

.nav-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    /* Distribute space between items */
    align-items: center;
}

.nav-left {
    display: flex;
    align-items: center;
}

.nav-right {
    display: flex;
    align-items: center;
}

nav ul li {
    display: flex;
    align-items: center;
}

nav ul li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

nav ul li a:hover {
    background-color: #111;
}

/* Style for the logo */
.logo {
    height: 30px;
    /* Set logo height */
    width: auto;
    /* Maintain aspect ratio */
    margin-right: 10px;
    /* Add some space between the logo and the next element */
}

/* Style for the welcome message */
.welcome-message {
    display: flex;
    flex-direction: column;
    /* Stack child elements vertically */
    align-items: flex-end;
    /* Align text to the right */
    padding-right: 16px;
    /* Add padding to the right for spacing */
    color: white;
    text-align: right;
    /* Ensure text is aligned to the right */
    line-height: 1.2;
    /* Adjust line height for better readability */
}

/* Style for the login ID */
.login-id {
    font-size: 0.9em;
    /* Slightly smaller font for the LoginId */
    color: #ccc;
    /* Light grey color for differentiation */
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .nav-container {
        flex-direction: column;
        /* Stack nav items vertically */
    }

    .nav-left,
    .nav-right {
        width: 100%;
        /* Full width for nav sections on tablets */
        justify-content: center;
        /* Center-align items */
    }

    nav ul li {
        width: 100%;
        /* Full width for nav items */
        justify-content: center;
        /* Center-align items */
    }

    .welcome-message {
        text-align: center;
        /* Center-align text */
        align-items: center;
        /* Center-align items */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .nav-container {
        flex-direction: column;
        /* Stack nav items vertically */
    }

    .nav-left,
    .nav-right {
        width: 100%;
        /* Full width for nav sections on mobile */
        justify-content: center;
        /* Center-align items */
    }

    nav ul li {
        width: 100%;
        /* Full width for nav items */
        justify-content: center;
        /* Center-align items */
    }

    nav ul li a {
        padding: 10px 12px;
        /* Adjust padding for smaller screens */
    }

    .welcome-message {
        text-align: center;
        /* Center-align text */
        align-items: center;
        /* Center-align items */
    }
}