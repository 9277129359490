.test-suite-content {
    padding: 16px;
}

.test-suite-table {
    width: 100%;
    border-collapse: collapse;
}

.test-suite-table th,
.test-suite-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.test-suite-table th {
    cursor: pointer;
    background-color: #f2f2f2;
}

/* Additional space after the pagination */
.test-suite-pagination {
    margin-top: 16px;
    text-align: center;
    margin-bottom: 100px;
    /* Increased bottom margin for additional space */
}

.test-suite-pagination button {
    margin: 0 2px;
    padding: 8px 12px;
    cursor: pointer;
}

.test-suite-pagination button.disabled {
    background-color: #ccc;
    /* Gray color for disabled buttons */
    cursor: not-allowed;
}