/* Container to hold sidebar, content, and right sidebar */
.container {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Sidebar styling */
.sidebar {
  width: 250px; /* Fixed width for sidebar */
  background-color: #f4f4f4;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Content area styling */
.content {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Form styling */
.subscription-form {
  max-width: 100%;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  flex: 1; /* Take up remaining space */
}

.subscription-form button {
  padding: 10px !important;
  font-size: 16px !important;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
}

.subscription-form button:hover {
  background-color: #0056b3 !important;
}

.subscription-form input {
  flex: 1;
  min-width: 150px;
}

/* Right sidebar styling */
.right-sidebar {
  width: 200px; /* Fixed width for right sidebar */
  background-color: #e9e9e9;
  padding: 20px;
  box-sizing: border-box;
  margin-left: 20px;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.right-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.right-sidebar ul li {
  margin-bottom: 10px;
}

.right-sidebar ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.right-sidebar ul li a:hover {
  text-decoration: underline;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.scrollable-textarea {
  height: 100px;
  overflow-y: scroll;
}

.error {
  color: red;
  font-size: 0.875em;
}

.error-input {
  border: 2px solid red;
}

.subscription-form .submit-button {
  padding: 10px !important;
  font-size: 16px !important;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
}

.subscription-form .submit-button:hover {
  background-color: #0056b3 !important;
}

/* Form row styling for paired elements */
.form-row {
  display: flex;
  justify-content: space-between;
}

.form-row .form-group {
  flex: 1;
  margin-right: 10px;
}

.form-row .form-group:last-child {
  margin-right: 0;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column; /* Stack sidebar and content vertically */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .sidebar,
  .right-sidebar {
    width: 100%; /* Full width for sidebars on small screens */
    margin-left: 0;
  }

  .form-row {
    flex-direction: column;
  }

  .form-row .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .content {
    padding: 15px;
  }

  .subscription-form {
    padding: 15px;
  }

  .modal-content {
    padding: 15px;
  }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack sidebar and content vertically */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .sidebar,
  .right-sidebar {
    width: 100%; /* Full width for sidebars on small screens */
    margin-left: 0;
  }

  .form-row {
    flex-direction: column;
  }

  .form-row .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .content {
    padding: 10px;
  }

  .subscription-form {
    padding: 10px;
  }

  .modal-content {
    padding: 10px;
  }
}

/* Modal styling */
.modal {
  width: 90%; /* Ensure it takes up 90% of the viewport width */
  max-width: 800px; /* Increase the max-width */
  margin: auto;
  overflow-y: auto; /* Enable vertical scrolling if needed */
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content styling */
.modal-content {
  background-color: white;
  padding: 10px; /* Reduce padding */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it takes up the full width of the modal */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  overflow-x: hidden; /* Disable horizontal scrolling */
}
