/* Registration.css */

.registration-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 1.5em;
    border: 1px solid #ccc;
    border-radius: 1em;
    background-color: #f9f9f9;
    max-height: 80vh;
    /* Set a maximum height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.registration-form label {
    margin-top: 1em;
    display: block;
    font-weight: bold;
}

.registration-form input,
.registration-form select {
    width: 100%;
    padding: 0.5em;
    margin-top: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    box-sizing: border-box;
}

.registration-form input:focus,
.registration-form select:focus {
    border-color: #007BFF;
    outline: none;
}

.registration-form button {
    margin-top: 1.5em;
    padding: 0.7em;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    width: 100%;
}

.registration-form button:hover {
    background-color: #0056b3;
}

.registration-form span {
    color: red;
    font-size: 0.9em;
}

.registration-form p {
    margin-top: 1em;
    font-weight: bold;
    color: green;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .registration-form {
        max-width: 80%;
        /* Adjust width for tablets */
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .registration-form input,
    .registration-form select,
    .registration-form button {
        padding: 8px;
        /* Adjust padding for input fields, select, and button */
        font-size: 14px;
        /* Adjust font size for input fields, select, and button */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .registration-form {
        max-width: 90%;
        /* Adjust width for mobile devices */
        padding: 10px;
        /* Adjust padding for mobile devices */
    }

    .registration-form label {
        margin-top: 0.5em;
        /* Adjust margin for labels */
    }

    .registration-form input,
    .registration-form select,
    .registration-form button {
        padding: 8px;
        /* Adjust padding for input fields, select, and button */
        font-size: 14px;
        /* Adjust font size for input fields, select, and button */
    }

    .registration-form p {
        font-size: 14px;
        /* Adjust font size for paragraph */
    }
}