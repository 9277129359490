/* Privacy Policy Styles */

/* General Page Layout */
.privacy-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    max-height: 80vh;
    /* Ensure the container has a max height */
    overflow-y: auto;
    /* Enable vertical scrolling when content overflows */
}

/* Headings */
h1 {
    font-size: 28px;
    color: #333;
    margin-top: 20px;
}

h2 {
    font-size: 22px;
    color: #0073e6;
    margin-top: 15px;
}

p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Sections */
.section {
    margin-bottom: 40px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

li {
    margin-bottom: 10px;
    font-size: 16px;
}

.privacy-policy {
    margin-top: 20px;
}

/* Footer */
.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.footer-left {
    font-size: 14px;
    color: #777;
}

.footer-center {
    font-size: 14px;
}

.footer-right a {
    font-size: 14px;
    color: #0073e6;
    text-decoration: none;
}

.footer-right a:hover {
    text-decoration: underline;
}

a {
    color: #0073e6;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.privacy-policy ul {
    margin-bottom: 20px;
}

/* Contact Section */
.contact-section {
    padding-top: 20px;
}

.contact-section h2 {
    color: #333;
}

.contact-section p {
    font-size: 16px;
    color: #555;
}