/* MyTasks.css */

.dashboard-content {
    padding: 20px;
    background-color: #f9f9f9;
}

h2 {
    color: #333;
    text-align: center;
}

.task-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.task-table th,
.task-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.task-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.task-table td a {
    color: #007bff;
    text-decoration: none;
}

.task-table td a:hover {
    text-decoration: underline;
}

.pagination {
    margin-top: 20px;
    text-align: center;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #d4d4d4;
    cursor: not-allowed;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .dashboard-content {
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .task-table th,
    .task-table td {
        padding: 6px;
        /* Adjust padding for table cells */
    }

    .pagination button {
        padding: 8px 12px;
        /* Adjust padding for buttons */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .dashboard-content {
        padding: 10px;
        /* Adjust padding for mobile devices */
    }

    h2 {
        font-size: 1.5em;
        /* Adjust font size for mobile devices */
    }

    .task-table {
        font-size: 14px;
        /* Adjust font size for table on mobile devices */
    }

    .task-table th,
    .task-table td {
        padding: 6px;
        /* Adjust padding for table cells */
    }

    .pagination button {
        padding: 6px 10px;
        /* Adjust padding for buttons */
    }
}