/* Content area styling */
.content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* Form styling */
.dynamic-form {
    max-width: 100%;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    /* Ensure form takes full width */
}

.dynamic-form .submit-button {
    padding: 10px !important;
    font-size: 16px !important;
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    cursor: pointer !important;
    width: auto;
    margin-right: 10px;
    display: inline-block;
}

.dynamic-form .submit-button:hover {
    background-color: #0056b3 !important;
}

.button-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dynamic-form input {
    flex: 1;
    min-width: 150px;
    width: 100%;
    /* Ensure inputs take full width */
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.scrollable-textarea {
    height: 100px;
    overflow-y: scroll;
}

.error {
    color: red;
    font-size: 0.875em;
}

.dynamic-form .error-input {
    border: 2px solid red !important;
}

.wide-textarea {
    grid-column: span 3;
}

.dynamic-form .submit-button {
    padding: 10px !important;
    font-size: 16px !important;
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    cursor: pointer !important;
    width: auto;
    margin-right: 10px;
    display: inline-block;
}

.dynamic-form .submit-button:hover {
    background-color: #0056b3 !important;
}

/* Table styling for form fields */
.table-dynamic {
    width: 100%;
    border-collapse: collapse;
}

.table-dynamic th,
.table-dynamic td {
    padding: 10px !important;
    border: 1px solid #ddd !important;
    text-align: left !important;
}

.table-dynamic th {
    background-color: #f2f2f2 !important;
    font-weight: bold !important;
}

.table-dynamic tr:nth-child(even) {
    background-color: #f9f9f9 !important;
}

.table-dynamic tr:hover {
    background-color: #f1f1f1 !important;
}

.half-width {
    padding: 10px;
    width: 50%;
}

.full-width {
    padding: 10px;
    width: 100%;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .content {
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .form-group {
        margin-bottom: 10px;
        /* Adjust margin for form groups */
    }

    .dynamic-form .submit-button {
        padding: 8px;
        /* Adjust padding for submit button */
        font-size: 14px;
        /* Adjust font size for submit button */
        width: auto;
        /* Ensure width adjusts for tablets */
    }

    .table-dynamic th,
    .table-dynamic td {
        padding: 8px;
        /* Adjust padding for table cells */
    }

    .modal {
        width: 100%;
        /* Full width for tablets */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .content {
        flex-direction: column;
        /* Stack content vertically */
        padding: 10px;
        /* Adjust padding for mobile devices */
    }

    .form-group {
        margin-bottom: 10px;
        /* Adjust margin for form groups */
    }

    .dynamic-form {
        width: 100%;
        /* Ensure dynamic form takes full width */
    }

    .dynamic-form .submit-button {
        padding: 8px;
        /* Adjust padding for submit button */
        font-size: 14px;
        /* Adjust font size for submit button */
        width: 100%;
        /* Full width for mobile devices */
    }

    .dynamic-form input,
    .form-group input,
    .form-group textarea,
    .form-group select {
        width: 100%;
        /* Ensure inputs take full width */
    }

    .table-dynamic th,
    .table-dynamic td {
        padding: 8px;
        /* Adjust padding for table cells */
    }

    .half-width {
        width: 100%;
        /* Full width for mobile devices */
        padding: 5px;
    }

    .full-width {
        width: 100%;
        /* Full width for mobile devices */
        padding: 5px;
    }

    .modal {
        width: 100%;
        /* Full width for mobile devices */
    }

    .modal-content {
        width: 100%;
        /* Full width for mobile devices */
    }
}

/* Modal styling */
.modal {
    width: 90%;
    max-width: 800px;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal content styling */
.modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}