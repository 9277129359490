.timesheet-list {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.timesheet-list h2 {
    margin-bottom: 20px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.search-container {
    margin-bottom: 20px;
}

.search-container label {
    margin-right: 10px;
}

.search-container input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

.green-row {
    background-color: lightgreen;
}

.yellow-row {
    background-color: lightyellow;
}