/* BoardTasks.css */

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

th,
td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

th {
    cursor: pointer;
    background-color: #f2f2f2;
}

th:hover {
    background-color: #e0e0e0;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

input[type="date"],
input[type="number"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

span {
    display: inline-block;
    width: 100%;
}

span:hover {
    background-color: #f1f1f1;
}

.react-select__control {
    width: 100%;
}

.react-select__menu {
    width: 100%;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination button {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #e0e0e0;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

.pagination button.disabled {
    background-color: #ccc;
    /* Gray color for disabled buttons */
    cursor: not-allowed;
}
/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    table {
        font-size: 14px;
        /* Adjust font size for tablets */
    }

    th,
    td {
        padding: 10px;
        /* Adjust padding for table cells */
    }

    button,
    .pagination button {
        padding: 6px 12px;
        /* Adjust padding for buttons */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    table {
        font-size: 12px;
        /* Adjust font size for mobile devices */
    }

    th,
    td {
        padding: 8px;
        /* Adjust padding for table cells */
    }

    button,
    .pagination button {
        padding: 5px 10px;
        /* Adjust padding for buttons */
    }

    .react-select__control,
    .react-select__menu {
        width: 100%;
        /* Ensure select control and menu fit on mobile devices */
    }
}