/* Activation.css */

.activation-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.activation-form {
    display: flex;
    flex-direction: column;
}

.activation-form h2 {
    text-align: center;
}

.activation-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.activation-form button {
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.activation-form button:hover {
    background-color: #0056b3;
}

p {
    text-align: center;
    color: green;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .activation-container {
        max-width: 80%;
        /* Adjust width for tablets */
        padding: 15px;
        /* Adjust padding for tablets */
    }

    .activation-form input,
    .activation-form button {
        padding: 8px;
        /* Adjust padding for input fields and button */
        font-size: 14px;
        /* Adjust font size for input fields and button */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .activation-container {
        max-width: 90%;
        /* Adjust width for mobile devices */
        padding: 10px;
        /* Adjust padding for mobile devices */
    }

    .activation-form h2 {
        font-size: 18px;
        /* Adjust font size for mobile devices */
    }

    .activation-form input,
    .activation-form button {
        padding: 8px;
        /* Adjust padding for input fields and button */
        font-size: 14px;
        /* Adjust font size for input fields and button */
    }

    p {
        font-size: 14px;
        /* Adjust font size for paragraph */
    }
}