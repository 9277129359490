/* Container styling */
.app-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* Allow scrolling within the app container */
}

/* Header styling */
.header {
  text-align: left;
  margin-bottom: 20px;
}

/* Content container styling */
.content-container {
  display: flex;
  flex-direction: row;
}

/* Main content styling */
.main-content {
  flex: 1;
  margin-right: 20px;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Search form styling */
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center; /* Ensure alignment of items */
}

.search-form input,
.search-form button,
.search-form .react-datepicker-wrapper,
.search-form .react-datepicker__input-container input,
.search-form select {
  padding: 10px;
  font-size: 16px;
  height: 40px; /* Set a fixed height for input fields, date picker, and select */
  box-sizing: border-box; /* Ensure padding is included in the height */
}

.search-form .input-field {
  flex: 1;
  min-width: 150px;
}

.search-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.search-form button:hover {
  background-color: #0056b3;
}

/* Sidebar styling */
.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  border-left: 1px solid #ddd;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Right sidebar styling */
.right-sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  border-left: 1px solid #ddd;
  overflow-y: auto; /* Enable vertical scrolling if needed */
  margin-bottom: 50px; /* Add bottom margin */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 20px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

/* Results styling */
.results {
  margin-top: 20px;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

/* Link styling */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #e0e0e0;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

/* Modal content styling */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%; /* Increase the width */
  max-height: 80vh; /* Set a maximum height */
  margin: auto;
  overflow-y: auto; /* Make the modal scrollable */
}

/* Close button styling */
.close-button {
  padding: 10px;
  font-size: 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #c82333;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
  .app-container {
    padding: 10px;
  }

  .content-container {
    flex-direction: column; /* Stack sidebar and content vertically */
  }

  .main-content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sidebar,
  .right-sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .modal-content {
    max-width: 90%; /* Adjust width for tablets */
    max-height: 90vh; /* Adjust height for tablets */
  }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
  .app-container {
    padding: 5px;
  }

  .content-container {
    flex-direction: column; /* Stack sidebar and content vertically */
  }

  .main-content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sidebar,
  .right-sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .modal-content {
    max-width: 90%; /* Adjust width for smaller screens */
    max-height: 90vh; /* Adjust height for smaller screens */
  }
}

/* Ensure only one scroll bar */
body {
  overflow: hidden; /* Prevent body from scrolling */
}

/* Success message styling */
.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Error message styling */
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Cancel button styling */
.cancel-button {
  padding: 10px !important;
  font-size: 16px !important;
  background-color: #dc3545 !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #c82333 !important;
}
