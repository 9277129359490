/* TestResultsGraph.css */

.test-results-graph {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.test-results-graph canvas {
    width: 100% !important;
    height: auto !important;
}

/* Media Queries */

/* For tablets (up to 1024px) */
@media screen and (max-width: 1024px) {
    .test-results-graph {
        padding: 15px;
        /* Adjust padding for tablets */
    }
}

/* For mobile devices (up to 768px) */
@media screen and (max-width: 768px) {
    .test-results-graph {
        padding: 10px;
        /* Adjust padding for mobile devices */
        border-radius: 5px;
        /* Adjust border radius for mobile devices */
    }

    .test-results-graph canvas {
        width: 100% !important;
        height: auto !important;
    }
}